import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { ensureHash, stripHash } from "../../utils/common";
import SiteGrid from "../../components/SiteGrid";
import { Info } from "./styles";
import { Link } from "react-router-dom";
import { newestToOldest } from "../../utils/sort-fns";

export default withRouter((props) => {
  const color = get(props, ["match", "params", "color"]);
  const { all: sites } = useSelector((store) => store.sites);

  // Function to convert hex to RGB
  const hexToRgb = (hex) => {
    hex = stripHash(hex);
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return { r, g, b };
  };

  // Function to calculate color similarity percentage
  const getColorSimilarity = (color1, color2) => {
    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);

    // Calculate Euclidean distance in RGB space
    const distance = Math.sqrt(
      Math.pow(rgb1.r - rgb2.r, 2) +
      Math.pow(rgb1.g - rgb2.g, 2) +
      Math.pow(rgb1.b - rgb2.b, 2)
    );

    // Max distance in RGB space is sqrt(3 * 255²)
    const maxDistance = Math.sqrt(3 * Math.pow(255, 2));

    // Convert to similarity percentage
    return 100 - (distance / maxDistance * 100);
  };

  // Filter sites with exact color match
  const exactColorSites = sites.filter((site) => {
    const colors = Object.values(site.colors).filter(Boolean).map(stripHash);
    return colors.includes(stripHash(color));
  });

  // Filter sites with similar colors (within 10% difference) that are not exact matches
  const similarColorSites = sites.filter((site) => {
    // Skip sites that already have an exact match
    if (exactColorSites.includes(site)) return false;

    const siteColors = Object.values(site.colors).filter(Boolean);

    // Check if any color is similar (90% similarity or higher)
    return siteColors.some(siteColor => {
      const similarity = getColorSimilarity(color, siteColor);
      return similarity >= 90;
    });
  });

  // Combine exact and similar color sites and sort them
  const allColorSites = [...exactColorSites, ...similarColorSites];
  const sortedColorSites = allColorSites.sort(newestToOldest);
  const colorHex = ensureHash(color);

  useEffect(() => {
    try {
      const scroll = get(props, "location.state.scroll");
      if (scroll && window) {
        window.scrollTo(0, 0);
        props.history.replace({
          pathname: props.location.pathname,
          state: { scroll: false },
        });
      }
    } catch (err) {
      console.warn(err);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Sites that use {colorHex} - CSSline</title>
      </Helmet>
      <Info>
        <h1>
		Sites that use colors similar to <i>{colorHex}</i> 👍
        </h1>
        <Link to="/" className="link">Go to Index</Link>
        <div className="marquee">
          <div className="marquee__inner" aria-hidden="true">
            <span>Go to Index 👉</span>
            <span>Go to Index 👉</span>
            <span>Go to Index 👉</span>
            <span>Go to Index 👉</span>
          </div>
        </div>
      </Info>
      <SiteGrid sites={sortedColorSites} />
    </div>
  );
});
